import { Box, Divider, ListItem, ListItemButton } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import { ReactNode } from 'react';

import { testId } from 'utils/test-id.utils';

interface Props {
  children: ReactNode;
  divider?: boolean;
  onClick?: () => void;
  testIdentifier?: string;
  style?: CommonProps['style'];
}

const CountryMenuListItem = ({
  children,
  divider = true,
  onClick,
  testIdentifier,
  style,
}: Props) => {
  return (
    <div style={style}>
      <ListItem disablePadding disableGutters {...testId(testIdentifier)}>
        <ListItemButton onClick={onClick} disableGutters disabled={!onClick}>
          <Box mx={8} py={1}>
            {children}
          </Box>
        </ListItemButton>
      </ListItem>
      {divider && <Divider sx={{ mx: 5 }} />}
    </div>
  );
};

export default CountryMenuListItem;
