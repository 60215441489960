import { Box, Skeleton } from '@mui/material';

const ProfileLoading = () => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Skeleton variant="circular" height={68} width={68} />
      <Box ml={2}>
        <Skeleton variant="text" width={130} />
        <Skeleton variant="text" width={110} />
      </Box>
    </Box>
  );
};

export default ProfileLoading;
