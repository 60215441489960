import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M4 21V20C4 16.6863 6.68629 14 10 14H14C17.3137 14 20 16.6863 20 20V21"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="gradient1"
        x1={3.99999}
        y1={21}
        x2={9.14098}
        y2={9.24918}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE402A" />
        <stop offset={1} stopColor="#A101FF" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgIcoAccount);
export default Memo;
