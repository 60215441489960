import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgRiaLogoSm = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
    <path d="M5 21.9558V27.5015H9.07461V22.6349C9.07461 20.4764 10.2751 19.08 13.1957 18.982V14.9141C7.82931 15.0037 5 17.7882 5 21.9558Z" />
    <path d="M28.5476 14.7336C24.7652 14.7551 22.0488 17.4732 22.0488 21.2091C22.0488 24.8985 24.3136 27.321 28.1392 27.6846L30.8107 25.6689L30.9021 25.7137V27.502H34.9999V21.2091C34.9999 17.2906 32.4263 14.7103 28.5476 14.7336ZM28.5476 23.6997C27.1429 23.6997 26.1251 22.657 26.1251 21.2091C26.1251 19.7612 27.1429 18.7185 28.5476 18.7185C29.9523 18.7185 30.9236 19.7596 30.9236 21.2091C30.9236 22.6586 29.9357 23.6997 28.5476 23.6997Z" />
    <path d="M19.9652 14.9141H15.8906V27.5032H19.9652V14.9141Z" />
    <path d="M17.9276 7.76856C17.3905 7.76888 16.8654 7.92814 16.4189 8.2262C15.9724 8.52426 15.6245 8.94773 15.4192 9.44307C15.2138 9.93842 15.1603 10.4834 15.2653 11.0091C15.3703 11.5348 15.6291 12.0177 16.0091 12.3966C16.3891 12.7756 16.8731 13.0336 17.4 13.138C17.9268 13.2425 18.4729 13.1887 18.9692 12.9835C19.4655 12.7783 19.8896 12.4308 20.188 11.9851C20.4864 11.5393 20.6457 11.0153 20.6457 10.4793C20.6459 10.1231 20.5758 9.77037 20.4392 9.44128C20.3027 9.1122 20.1024 8.81321 19.85 8.56143C19.5975 8.30966 19.2978 8.11003 18.9679 7.97398C18.6381 7.83793 18.2846 7.76812 17.9276 7.76856V7.76856Z" />
  </svg>
);
const Memo = memo(SvgRiaLogoSm);
export default Memo;
