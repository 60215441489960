import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import IcoSearch from 'components/@icons/IcoSearch';

const SearchField = ({ children, ...rest }: TextFieldProps) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'brand.darkBlue' }}>
            <IcoSearch width={24} height={24} fill="currentColor" />
          </InputAdornment>
        ),
      }}
    >
      {children}
    </TextField>
  );
};

export default SearchField;
