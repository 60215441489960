import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7642 4C8.97021 4 7.24972 4.71265 5.98118 5.98118C4.71265 7.24971 4 8.97021 4 10.7642C4 12.5582 4.71265 14.2787 5.98118 15.5472C7.24972 16.8157 8.97021 17.5284 10.7642 17.5284C12.2215 17.5284 13.6303 17.0581 14.7877 16.2016L18.2932 19.7071C18.6838 20.0977 19.3169 20.0977 19.7074 19.7071C20.098 19.3166 20.098 18.6834 19.7074 18.2929L16.2019 14.7874C17.0582 13.63 17.5284 12.2213 17.5284 10.7642C17.5284 8.97021 16.8157 7.24971 15.5472 5.98118C14.2787 4.71265 12.5582 4 10.7642 4ZM7.3954 7.3954C8.28886 6.50194 9.50064 6 10.7642 6C12.0277 6 13.2395 6.50194 14.133 7.3954C15.0264 8.28886 15.5284 9.50065 15.5284 10.7642C15.5284 12.0277 15.0264 13.2395 14.133 14.133C13.2395 15.0264 12.0277 15.5284 10.7642 15.5284C9.50064 15.5284 8.28886 15.0264 7.3954 14.133C6.50194 13.2395 6 12.0277 6 10.7642C6 9.50065 6.50194 8.28886 7.3954 7.3954Z"
    />
  </svg>
);
const Memo = memo(SvgIcoSearch);
export default Memo;
