import { Button } from '@mui/material';
import { getName } from 'i18n-iso-countries';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import { Countries } from 'providers/country';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { testId } from 'utils/test-id.utils';

import { useCountries } from 'hooks/useCountries';
import { useIsDomestic } from 'hooks/useIsDomestic';
import { useIsMounted } from 'hooks/useIsMounted';
import { useLocale } from 'hooks/useLocale';
import { selectSenderCountry } from 'services/avo/avo';

import IcoChevron from 'components/@icons/IcoChevron';
import IcoDropdown from 'components/@icons/IcoDropdown';

import CountryMenuDropdown from './CountryMenuDropdown';

interface Props {
  variant?: 'mobile' | 'desktop';
}

const CountryMenu = ({ variant = 'desktop' }: Props) => {
  const isMounted = useIsMounted();

  const locale = useLocale();
  const { checkIsDomestic } = useIsDomestic();

  const { senderCountry, updateCountries } = useCountries();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectCountry = (code: string) => {
    setAnchorEl(null);
    const countries: Partial<Countries> = {
      senderCountry: code,
    };

    if (checkIsDomestic(code)) {
      countries.receiverCountry = code;
    }

    updateCountries(countries);
    selectSenderCountry({ senderCountry: code.toUpperCase() });
  };

  const isMobile = variant === 'mobile';
  const Icon = isMobile ? IcoChevron : IcoDropdown;

  return (
    <>
      {senderCountry && isMounted && (
        <Button
          onClick={handleClick}
          variant="text"
          aria-controls={open ? 'country-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          fullWidth={isMobile}
          {...testId('linkSendFrom')}
          sx={{
            p: isMobile ? 4 : 0,
            justifyContent: 'space-between',
            mb: isMobile ? 4 : 0,
            borderRadius: isMobile ? '10px' : 0,
            border: isMobile ? '1px solid #D9D9D9' : 'unset',
            fontWeight: isMobile ? 400 : 700,
          }}
          endIcon={
            <Icon
              fill="currentColor"
              style={{
                transform: open ? 'rotate(180deg)' : undefined,
                transition: 'transform 0.2s',
              }}
            />
          }
        >
          <FormattedMessage
            id="navigation.country.from.label"
            values={{
              country: getName(senderCountry, locale),
            }}
          />
        </Button>
      )}
      <CountryMenuDropdown
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSelect={handleSelectCountry}
        open={open}
        isMobile={isMobile}
      />
    </>
  );
};

export default CountryMenu;
