import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useLocale } from 'hooks/useLocale';
import QueryKeys from 'queries/QueryKeys';
import { apiService } from 'services';

import { StaleTime } from './types';

const getMaintenance = async (ountry: string) => {
  const { data } = await apiService.getMaintenance(ountry);

  return data;
};

export const useMaintenance = (receiverCountry: string) => {
  const locale = useLocale() as string;
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: QueryKeys.maintenance(receiverCountry),
    queryFn: () => getMaintenance(receiverCountry),
    gcTime: StaleTime.FIFTEEN_MIN,
    staleTime: StaleTime.MINUTE * 10,
  });

  const maintenance = useMemo(() => {
    if (data) {
      if (locale === 'en') {
        return data.english;
      } else if (locale === 'fr') {
        return data.french;
      } else if (locale === 'es') {
        return data.spanish;
      } else if (locale === 'nl') {
        return data.dutch;
      }
    }
  }, [data, locale]);

  return {
    maintenance,
    isLoading,
    error,
    refetch,
  };
};
