import { QueryClient, useQuery } from '@tanstack/react-query';

import { useAuth } from 'hooks/useAuth';
import QueryKeys from 'queries/QueryKeys';
import { StaleTime, UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { User } from 'types/user.types';

const getUserProfile = async () => {
  const { data } = await apiService.getUserProfile();
  return data;
};

export const prefetchUserProfile = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: QueryKeys.user.profile(),
    queryFn: () => getUserProfile(),
  });
};

export const useUserProfile = (config?: UseCommonQueryOptions<User>) => {
  const { isLoggedIn } = useAuth();
  const { data, error, isError, isLoading, isFetching } = useQuery({
    queryKey: QueryKeys.user.profile(),
    queryFn: () => getUserProfile(),
    enabled: isLoggedIn,
    staleTime: StaleTime.FIVE_MIN,
    gcTime: StaleTime.FOREVER,
    ...config,
  });

  return {
    user: data,
    isLoading,
    error,
    isError,
    isFetching,
  };
};
