import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <g clipPath="url(#clip0_9400_74604)">
      <path d="M9.17489 1.52917C4.95439 1.52917 1.52905 4.95451 1.52905 9.17501C1.52905 13.3955 4.95439 16.8208 9.17489 16.8208C13.3954 16.8208 16.8207 13.3955 16.8207 9.17501C16.8207 4.95451 13.3954 1.52917 9.17489 1.52917ZM9.17489 3.82292C10.4441 3.82292 11.4686 4.84747 11.4686 6.11667C11.4686 7.38588 10.4441 8.41042 9.17489 8.41042C7.90568 8.41042 6.88114 7.38588 6.88114 6.11667C6.88114 4.84747 7.90568 3.82292 9.17489 3.82292ZM9.17489 14.68C7.26343 14.68 5.5737 13.7013 4.58739 12.218C4.61032 10.6965 7.64572 9.86313 9.17489 9.86313C10.6964 9.86313 13.7394 10.6965 13.7624 12.218C12.7761 13.7013 11.0863 14.68 9.17489 14.68Z" />
    </g>
    <defs>
      <clipPath id="clip0_9400_74604">
        <rect width={18.35} height={18.35} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoProfile);
export default Memo;
