import { Button, CircularProgress, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { testId } from 'utils/test-id.utils';

import { useAuth } from 'hooks/useAuth';
import { useIsMounted } from 'hooks/useIsMounted';

import IcoProfile from 'components/@icons/IcoProfile';
import NavigationMenu from 'components/@navigation/NavigationMenu';

interface Props {
  onLogin(): void;
}

const ProfileButton: React.FC<Props> = ({ onLogin }) => {
  const isMounted = useIsMounted();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isLoggedIn, logout } = useAuth();

  if (!isMounted)
    return <CircularProgress size={24} color="secondary" sx={{ ml: 5 }} />;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  if (!isLoggedIn)
    return (
      <Button
        variant="contained"
        size="small"
        onClick={onLogin}
        sx={{ ml: 5, flex: 1 }}
      >
        <FormattedMessage id="navigation.sign_in.button" />
      </Button>
    );

  return (
    <>
      <IconButton
        {...testId('profileLink')}
        sx={{ ml: 5, color: 'brand.darkBlue' }}
        onClick={handleClick}
        aria-label="profile button"
      >
        <IcoProfile fontSize={30} fill="currentColor" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id="country-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        slotProps={{
          paper: {
            sx: { py: 5 },
          },
        }}
        // keepMounted
      >
        <NavigationMenu px={5} />
        <Button
          variant="contained"
          size="small"
          onClick={handleLogout}
          sx={{ mt: 2, mx: 5 }}
        >
          <FormattedMessage id="navigation.sign_out.button" />
        </Button>
      </Popover>
    </>
  );
};

export default ProfileButton;
