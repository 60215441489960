import { Box, BoxProps, List } from '@mui/material';

import { Routes } from 'constants/routes.constants';
import { useMda } from 'hooks/useMda';
import { useUserProfile } from 'queries/user/useUserProfile';

import Profile from 'components/@profile/Profile';
import ProfileLoading from 'components/@profile/ProfileLoading';

import { IcoAccount, IcoMoneyBill, IcoReceipt } from './icons';
import MenuListItem from './MenuListItem';

interface Props {
  px?: BoxProps['px'];
}

const NavigationMenu = ({ px = 0 }: Props) => {
  const { isMda } = useMda();
  const { user, isLoading } = useUserProfile();

  return (
    <>
      {!isMda && (
        <Box px={px}>
          {isLoading && !user && <ProfileLoading />}
          {!isLoading && user && <Profile user={user} />}
        </Box>
      )}
      <List sx={{ mt: 4 }}>
        <MenuListItem
          px={px}
          labelId="menu.item.transaction_history"
          Icon={IcoReceipt}
          iconType="fill"
          route={Routes.Transactions}
          testIdentifier="menuItemTransactionHistory"
        />
        <MenuListItem
          px={px}
          labelId="menu.item.frequent_payments"
          Icon={IcoMoneyBill}
          iconType="fill"
          route={Routes.AutoPays}
          testIdentifier="menuItemFrequentPayments"
        />
        {!isMda && (
          <MenuListItem
            px={px}
            labelId="menu.item.my_account"
            Icon={IcoAccount}
            iconType="stroke"
            route={Routes.Profile}
            testIdentifier="menuItemMyAccount"
          />
        )}
      </List>
    </>
  );
};

export default NavigationMenu;
