import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <path d="M13.2234 8.18514C13.5212 7.88728 13.5212 7.40435 13.2234 7.10649C12.9257 6.80885 12.4433 6.80859 12.1453 7.1059L9.52836 9.71714C9.33319 9.91189 9.0172 9.91189 8.82203 9.71714L6.20508 7.1059C5.90713 6.80859 5.42465 6.80885 5.12702 7.10649C4.82916 7.40435 4.82916 7.88728 5.12702 8.18514L8.11453 11.1727C8.70032 11.7584 9.65007 11.7584 10.2359 11.1727L13.2234 8.18514Z" />
  </svg>
);
const Memo = memo(SvgIcoChevron);
export default Memo;
