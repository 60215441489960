import { Box, Popover } from '@mui/material';
import { getNames } from 'i18n-iso-countries';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FixedSizeList as VirtualizedList } from 'react-window';

import { testId } from 'utils/test-id.utils';

import { useLocale } from 'hooks/useLocale';

import SearchField from 'components/@inputs/SearchField';

import CountryMenuListItem from './CountryMenuListItem';

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose(): void;
  isMobile: boolean;
  onSelect(country: string): void;
}

const CountryMenuDropdown: React.FC<Props> = ({
  anchorEl,
  open,
  onClose,
  isMobile,
  onSelect,
}) => {
  const intl = useIntl();
  const locale = useLocale();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const filteredCountries = useMemo(() => {
    const countries = getNames(locale);
    const filteredCountries = Object.entries(countries).filter(([_, value]) =>
      value.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
    return Object.fromEntries(filteredCountries);
  }, [debouncedSearchValue, locale]);

  const searchDebounce = useMemo(
    () => debounce((value: string) => setDebouncedSearchValue(value), 100),
    [],
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      searchDebounce(value);
    },
    [searchDebounce],
  );

  const handleClose = () => {
    handleSearchChange('');
    onClose();
  };

  const countries = Object.entries(filteredCountries);

  return (
    <Popover
      anchorEl={anchorEl}
      id={isMobile ? 'country-menu-dropdown-mobile' : 'country-menu-dropdown'}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxHeight={350}
        width={isMobile ? '100%' : 300}
      >
        <SearchField
          sx={{ mx: 5, pt: 5 }}
          value={searchValue}
          onChange={(e) => handleSearchChange(e.currentTarget.value)}
          placeholder={intl.formatMessage({
            id: 'navigation.country.search.placeholder',
          })}
          {...testId('searchSendFrom')}
        />
        <Box my={2}>
          {!isEmpty(filteredCountries) ? (
            <VirtualizedList
              width="100%"
              height={270}
              itemCount={countries.length}
              itemSize={48}
            >
              {({ index, style }) => (
                <CountryMenuListItem
                  style={style}
                  onClick={() => onSelect(countries[index][0].toLowerCase())}
                  testIdentifier={countries[index][0]}
                >
                  {countries[index][1]}
                </CountryMenuListItem>
              )}
            </VirtualizedList>
          ) : (
            <CountryMenuListItem divider={false}>
              <FormattedMessage id="navigation.country.search.empty" />
            </CountryMenuListItem>
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default CountryMenuDropdown;
