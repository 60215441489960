import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDropdown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
    <path d="M5 8L10 13L15 8H5Z" fillOpacity={0.54} />
  </svg>
);
const Memo = memo(SvgIcoDropdown);
export default Memo;
