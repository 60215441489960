import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <path d="M2.75244 13.7625H15.5974V11.9275H2.75244V13.7625ZM2.75244 4.58752V6.42252H15.5974V4.58752H2.75244ZM2.75244 10.0925H15.5974V8.25752H2.75244V10.0925Z" />
  </svg>
);
const Memo = memo(SvgIcoMenu);
export default Memo;
