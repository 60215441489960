import { User } from 'types/user.types';

export const shortName = (user: User) => {
  let name = user.email;

  if (user.firstName && user.lastName) {
    name = `${user.firstName} ${user.lastName}`;
  }

  const split = name.split(' ');
  let shortName = undefined;

  if (split.length > 0) {
    shortName = split[0][0];
    if (split.length > 1) {
      shortName += split[1][0];
    } else {
      shortName = split[0].substring(0, 2);
    }
  }

  return shortName?.toUpperCase();
};

export const displayName = (user: User) => {
  let name = user.email;

  if (user.firstName && user.lastName) {
    name = `${user.firstName} ${user.lastName}`;
  }

  return name;
};
