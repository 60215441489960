import {
  BoxProps,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, SVGProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { testId } from 'utils/test-id.utils';

interface Props {
  labelId: string;
  Icon: React.MemoExoticComponent<
    (props: SVGProps<SVGSVGElement>) => ReactNode
  >;
  iconType: 'fill' | 'stroke';
  route: string;
  px: BoxProps['px'];
  testIdentifier?: string;
}

const MenuListItem = ({
  labelId,
  Icon,
  iconType,
  route,
  px,
  testIdentifier,
}: Props) => {
  const { pathname } = useRouter();
  const theme = useTheme();
  const selected = pathname === route;

  const defaultFill =
    iconType === 'fill' ? theme.palette.brand.darkBlue : 'none';
  const defaultStroke =
    iconType === 'stroke' ? theme.palette.brand.darkBlue : 'none';

  return (
    <>
      <ListItem
        sx={{ height: 64, px: 0 }}
        disablePadding
        {...testId(testIdentifier)}
      >
        <Link href={{ pathname: route }} legacyBehavior passHref>
          <ListItemButton
            disableGutters
            sx={{ height: '100%', px: px }}
            disabled={selected}
          >
            <ListItemIcon
              sx={{ minWidth: 'unset', mr: 4, ml: 2 }}
              color="brand.darkBlue"
            >
              <Icon
                fontSize={28}
                fill={
                  selected && iconType === 'fill'
                    ? 'url(#gradient1)'
                    : defaultFill
                }
                stroke={
                  selected && iconType === 'stroke'
                    ? 'url(#gradient1)'
                    : defaultStroke
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="h4"
                component="span"
                color={selected ? 'gradient.primary' : 'brand.darkBlue'}
              >
                <FormattedMessage id={labelId} />
              </Typography>
            </ListItemText>
          </ListItemButton>
        </Link>
      </ListItem>
      <Divider sx={{ mx: px }} />
    </>
  );
};

export default MenuListItem;
