import { Avatar, Box, Typography } from '@mui/material';

import { displayName, shortName } from 'utils/user.utils';

import { User } from 'types/user.types';

interface Props {
  user: User;
}

const Profile = ({ user }: Props) => {
  const name = displayName(user);
  const avatarName = shortName(user);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {avatarName && (
        <Avatar variant="profile" sx={{ width: 68, height: 68 }}>
          {avatarName}
        </Avatar>
      )}
      <Box ml={2} display="flex" flexDirection="column" overflow="hidden">
        <Typography
          variant="h4"
          component="span"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {name}
        </Typography>
        {user.phoneNumber && (
          <Typography
            variant="body1"
            component="span"
            color="support.neutral02"
          >
            {user.phoneNumber}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
