import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <path d="M14.3338 4.90094L13.2701 3.82288L9.05289 8.0969L4.8357 3.82288L3.77197 4.90094L7.98916 9.17496L3.77197 13.449L4.8357 14.527L9.05289 10.253L13.2701 14.527L14.3338 13.449L10.1166 9.17496L14.3338 4.90094Z" />
  </svg>
);
const Memo = memo(SvgIcoClose);
export default Memo;
