import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'hooks/useAuth';
import { useMda } from 'hooks/useMda';

import CountryMenu from 'components/@navigation/CountryMenu';
import NavigationDrawer from 'components/@navigation/NavigationDrawer';
import NavigationMenu from 'components/@navigation/NavigationMenu';

interface Props {
  onLogin(): void;
}

const MobileDrawer: React.FC<Props> = ({ onLogin }) => {
  const { isMda } = useMda();
  const [open, setOpen] = useState(false);
  const { isLoggedIn, logout } = useAuth();

  const handleLogout = () => {
    logout();
    setOpen(false);
  };

  return (
    <Box display={{ xs: 'block', sm: 'none' }}>
      <NavigationDrawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {isLoggedIn && <NavigationMenu />}
        {!isMda && (
          <>
            <Box mt={isLoggedIn ? 5 : 0} mb={3}>
              <CountryMenu variant="mobile" />
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={isLoggedIn ? handleLogout : onLogin}
            >
              <FormattedMessage
                id={
                  isLoggedIn
                    ? 'navigation.sign_out.button'
                    : 'navigation.sign_in.button'
                }
              />
            </Button>
          </>
        )}
      </NavigationDrawer>
    </Box>
  );
};

export default MobileDrawer;
