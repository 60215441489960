import { useScrollTrigger } from '@mui/material';
import { cloneElement, ReactElement } from 'react';

interface ElevationScrollProps {
  children: ReactElement;
}

export default function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 2 : 1,
    style: {
      transition: trigger ? 'none' : 'background-color 500ms ease-in',
    },
  });
}
