import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Toolbar,
} from '@mui/material';
import { ReactNode } from 'react';

import IcoClose from 'components/@icons/IcoClose';
import IcoMenu from 'components/@icons/IcoMenu';

interface Props {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}
const NavigationDrawer = ({ children, onOpen, onClose, open }: Props) => {
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={() => onOpen()}
        sx={{ color: 'brand.red' }}
      >
        <IcoMenu fill="currentColor" />
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="right"
        hideBackdrop
        open={open}
        onClose={() => onClose()}
        ModalProps={
          {
            // keepMounted: true,
          }
        }
      >
        <Box width="100vw">
          <AppBar elevation={0} position="relative">
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close drawer"
                edge="end"
                onClick={() => onClose()}
                sx={{ color: 'brand.red' }}
              >
                <IcoClose fill="currentColor" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container>{children}</Container>
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
