import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoReceipt = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <path d="M5 7.8125C5 7.06658 5.29632 6.35121 5.82376 5.82376C6.35121 5.29632 7.06658 5 7.8125 5H18.4375C19.1834 5 19.8988 5.29632 20.4262 5.82376C20.9537 6.35121 21.25 7.06658 21.25 7.8125V17.5H25.625V21.5625C25.625 22.6399 25.197 23.6733 24.4351 24.4351C23.6733 25.197 22.6399 25.625 21.5625 25.625H9.0625C7.98506 25.625 6.95175 25.197 6.18988 24.4351C5.42801 23.6733 5 22.6399 5 21.5625V7.8125ZM21.25 19.375V23.75H21.5625C22.1427 23.75 22.6991 23.5195 23.1093 23.1093C23.5195 22.6991 23.75 22.1427 23.75 21.5625V19.375H21.25ZM19.375 23.75V7.8125C19.375 7.56386 19.2762 7.3254 19.1004 7.14959C18.9246 6.97377 18.6861 6.875 18.4375 6.875H7.8125C7.56386 6.875 7.3254 6.97377 7.14959 7.14959C6.97377 7.3254 6.875 7.56386 6.875 7.8125V21.5625C6.875 22.77 7.855 23.75 9.0625 23.75H19.375ZM8.75 10.9375C8.75 10.6889 8.84877 10.4504 9.02459 10.2746C9.2004 10.0988 9.43886 10 9.6875 10H16.5625C16.8111 10 17.0496 10.0988 17.2254 10.2746C17.4012 10.4504 17.5 10.6889 17.5 10.9375C17.5 11.1861 17.4012 11.4246 17.2254 11.6004C17.0496 11.7762 16.8111 11.875 16.5625 11.875H9.6875C9.43886 11.875 9.2004 11.7762 9.02459 11.6004C8.84877 11.4246 8.75 11.1861 8.75 10.9375ZM8.75 15.3125C8.75 15.0639 8.84877 14.8254 9.02459 14.6496C9.2004 14.4738 9.43886 14.375 9.6875 14.375H16.5625C16.8111 14.375 17.0496 14.4738 17.2254 14.6496C17.4012 14.8254 17.5 15.0639 17.5 15.3125C17.5 15.5611 17.4012 15.7996 17.2254 15.9754C17.0496 16.1512 16.8111 16.25 16.5625 16.25H9.6875C9.43886 16.25 9.2004 16.1512 9.02459 15.9754C8.84877 15.7996 8.75 15.5611 8.75 15.3125ZM8.75 19.6875C8.75 19.4389 8.84877 19.2004 9.02459 19.0246C9.2004 18.8488 9.43886 18.75 9.6875 18.75H12.8125C13.0611 18.75 13.2996 18.8488 13.4754 19.0246C13.6512 19.2004 13.75 19.4389 13.75 19.6875C13.75 19.9361 13.6512 20.1746 13.4754 20.3504C13.2996 20.5262 13.0611 20.625 12.8125 20.625H9.6875C9.43886 20.625 9.2004 20.5262 9.02459 20.3504C8.84877 20.1746 8.75 19.9361 8.75 19.6875Z" />
    <defs>
      <linearGradient
        id="gradient1"
        x1={3.99999}
        y1={21}
        x2={9.14098}
        y2={9.24918}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE402A" />
        <stop offset={1} stopColor="#A101FF" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgIcoReceipt);
export default Memo;
