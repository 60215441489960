import {
  AppBar,
  Box,
  ButtonBase,
  Container,
  Divider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';

import { Routes } from 'constants/routes.constants';
import { STORAGE_KEY_PRODUCT } from 'constants/storage.constants';
import { useCountries } from 'hooks/useCountries';
import { useMaintenance } from 'queries/useMaintenance';
import { useMultiStepActions } from 'store/multistep';

import DigicelLogomark from 'components/@icons/DigicelLogomark';
import DigicelWordmark from 'components/@icons/DigicelWordmark';
import RiaLogo from 'components/@icons/RiaLogoSm';
import CountryMenu from 'components/@navigation/CountryMenu';
import ElevationScroll from 'components/@navigation/ElevationScroll';
import MobileMenu from 'components/@navigation/MobileMenu';
import ProfileButton from 'components/@navigation/ProfileButton';

interface Props {
  hideCountry?: boolean;
  hideUser?: boolean;
  hideMenu?: boolean;
  fullMobileLogo?: boolean;
}

const NavigationHeader = ({
  hideCountry,
  hideUser,
  hideMenu,
  fullMobileLogo = false,
}: Props) => {
  const { senderCountry, receiverCountry } = useCountries();
  const router = useRouter();
  const { reset } = useMultiStepActions();

  const handleLogin = () => {
    router.push(
      {
        // Pass route to redirect too
        pathname: Routes.Login,
        query: { redirect: router.asPath },
      },
      Routes.Login,
    );
  };

  const handleLogoClick = () => {
    reset(true);
    if (router.pathname !== Routes.Root) {
      router.push({
        pathname: Routes.Root,
        query: {
          senderCountry,
          receiverCountry,
        },
      });
    }
    sessionStorage.removeItem(STORAGE_KEY_PRODUCT);
  };

  const { maintenance } = useMaintenance(receiverCountry);

  return (
    <>
      <ElevationScroll>
        <AppBar sx={{ px: 0 }}>
          <Container>
            <Toolbar sx={{ justifyContent: 'space-between' }} disableGutters>
              <Box gap={4} display="flex" alignItems="center">
                <ButtonBase
                  disableRipple
                  onClick={handleLogoClick}
                  aria-label="home"
                >
                  <Box height={34} color="brand.red" display="flex">
                    <DigicelLogomark
                      height={34}
                      width={30}
                      fill="currentColor"
                    />

                    <Box
                      ml={2}
                      display={{
                        xs: fullMobileLogo ? 'block' : 'none',
                        sm: 'block',
                      }}
                    >
                      <DigicelWordmark
                        height="100%"
                        width={80}
                        fill="currentColor"
                      />
                    </Box>
                  </Box>
                </ButtonBase>
                {router.pathname === Routes.SendMoney && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <RiaLogo fill="#FF6100" width={48} height={48} />
                  </>
                )}
              </Box>
              <Box>
                {/* Desktop view */}
                <Box
                  display={{ xs: 'none', sm: 'flex' }}
                  color="brand.darkBlue"
                >
                  {!hideCountry && <CountryMenu />}
                  {!hideUser && <ProfileButton onLogin={handleLogin} />}
                </Box>
                {/* Mobile view */}
                {!hideMenu && <MobileMenu onLogin={handleLogin} />}
              </Box>
            </Toolbar>
          </Container>
          {!!maintenance?.length && (
            <Box
              bgcolor="brand.darkBlue"
              minHeight={48}
              position="absolute"
              left={0}
              right={0}
              top={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={2}
            >
              <Container>
                <Typography
                  color="support.neutral01"
                  fontSize={16}
                  component="p"
                >
                  {maintenance}
                </Typography>
              </Container>
            </Box>
          )}
        </AppBar>
      </ElevationScroll>
      {/* A placeholder to calculate te space of the maintenance banner so the content gets pushed down*/}
      {!!maintenance?.length && (
        <Box py={2} visibility="hidden" minHeight={48}>
          <Container>
            <Typography color="support.neutral01" fontSize={16} component="p">
              {maintenance}
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
};

export default NavigationHeader;
